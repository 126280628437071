.content {
  //margin-bottom: 70px;
  font-family: var(--font-family) !important;

  .heading.heading_level-2 {
    font-weight: 700;
    margin-bottom: 22px;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--heading-font-family) !important;
  }

  h3 {
    color: $primary;
    font-weight: 700;
    font-size: $text-2xl !important;
  }

  span {
    color: $primary;
    font-weight: 700;
    font-size: $text-xl !important;
    font-family: var(--font-family) !important;
  }

  p {
    font-weight: 400;
    color: $gray-600;
    line-height: 24px;
    margin-bottom: 20px;
  }

  ol {
    list-style: auto;
  }

  ul {
    list-style: initial;
  }

  ol,
  ul {
    list-style-position: inside;
    li {
      margin-bottom: 10px;
    }
  }

  a {
    color: $primary;
  }

  pre {
    text-wrap: wrap;
  }

  @media screen and (min-width: 460px) {
    p[class~=block-img]:nth-of-type(2n+1) {
      float: left;
      max-width: 40%; 
      margin-right: 1rem;
      margin-bottom: 1rem;
      display: block;
    }

    p[class~=block-img]:nth-of-type(2n) {
      float: right;
      max-width: 40%;
      margin-left: 1rem; 
      margin-bottom: 1rem;
      display: block;
    }

    .block-img {
      overflow: hidden;
      text-align: justify;
    }
  }
}
