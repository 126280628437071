body {
  font-family: var(--font-family), ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  color: $text-color-default;
  background-color: $background-theme;
  line-height: 1.4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    margin-bottom: auto;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family:  var(--heading-font-family) !important;
    color: $heading-color;
  }

  /* .dark-theme {
    background-color: $background-theme-dark;
    color: $body-color-text;

    .heading,
    p,
    span {
      color: $heading-color;
    }
  } */
}

button {
  &:focus:not(:focus-visible) {
    outline: 0;
  }
}
