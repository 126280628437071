.page_not_found {
  margin-bottom: 350px;
  margin-top: 100px;
  user-select: none;

  @include media(md) {
    margin-bottom: 450px;
    margin-top: 20vh;
  }

  &__content {
    align-items: center;
    color: $gray-600;
    display: flex;
    flex-direction: column;
    position: relative;

    &__description {
      font-size: $text-xs;
      line-height: 1.733;
      text-align: center;

      @include media(sm) {
        font-size: $text-base;
      }
    }

    .heading {
      color: $not-found-page;
      font-size: 10rem;
      line-height: 1;

      @include media(sm) {
        font-size: 16.25rem;
      }

      &__descrip {
        color: $account-photo-username;
        font-size: $text-xl;
        font-weight: 500;
        position: absolute;
        top: 66px;

        @include media(sm) {
          font-size: $text-3xl;
          top: 110px;
        }
      }
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;

    .input_search {
      height: 45px;
      margin: 40px auto;
      width: 100%;
      max-width: 360px;

      @include media(sm) {
        height: 50px;
        align-self: center;
      }

      span {
        text-overflow: unset !important;
      }

      input::placeholder {
        color: $gray-500;
      }
    }

    .link {
      background: $btn-primary;
      border-radius: $border-radius-btn;
      color: $label-btn-primary;
      font-size: $text-sm;
      font-weight: 500;
      padding: 11px 30px;
      text-align: center;
      text-transform: uppercase;
      transition: $transition-all;
    }
  }
}
