.promotions__container {
  display: grid;
  grid-gap: 16px;
  margin-bottom: 32px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @media screen and (max-width: 560px) {
    max-width: 340px;
    margin: 0 auto 32px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  }

  @include media(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media(xl) {
    column-gap: 32px;
    row-gap: 24px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media(2xl) {
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  }

  .promotions__skeleton_item {
    padding-top: 49.01%;
    width: 100%;
    border-radius: $specials-card-border-radius;
  }
}

.promotion {
  border-radius: $specials-card-border-radius;

  h3 {
    @media screen and (min-width: 560px) and (max-width: 768px) {
      font-size: $text-sm !important;
      -webkit-line-clamp: 1 !important;
      line-clamp: 1 !important;
    }

    @media screen and (min-width: 769px) and (max-width: 1268px) {
      -webkit-line-clamp: 2 !important;
      line-clamp: 2 !important;
    }
  }

  p {
    @media screen and (min-width: 560px) and (max-width: 768px) {
      -webkit-line-clamp: 2 !important;
      line-clamp: 2 !important;
    }
  }

  a {
    @media screen and (min-width: 560px) and (max-width: 768px) {
      font-size: 0.625rem !important;
      padding: 2px 6px !important;
    }
  }
}

.promotion_empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  padding-top: 78px;
  padding-bottom: 108px;
  user-select: none;

  i {
    font-size: $text-3xl;
    color: $filter-icon-color;
    margin-bottom: 6px;
  }

  &__actions {
    column-gap: 5px;
    display: flex;
    flex-direction: row;
    max-width: 400px;

    .action {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  h5 {
    color: $heading-color;
  }
}

.promotion__filters {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  margin-bottom: 32px;
  margin-top: 16px;

  @include media(sm) {
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
  }

  @include media(md) {
    margin-top: 12px;
  }

  .promotion__dropdowns {
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;

    @include media(sm) {
      gap: 16px;
    }

    .dropdown {
      &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: $filter-btn-border-radius;
        padding: 18px 16px;
        height: 48px;
        gap: 20px;
        border: 1px solid $filter-btn-border;
        color: $filter-btn-text-color;
        background: $filter-btn-background;
        transition: all 0.3s ease;

        @include media(lg) {
          height: 56px;
        }

        > button {
          align-items: center;
          display: flex;
          gap: 20px;
        }

        > div {
          font-weight: normal !important;
          gap: 12px !important;
        }

        svg {
          * {
            stroke: $filter-btn-icon-color;
          }
        }

        & > svg {
          transition: all 0.3s ease;
          transform: rotate(0deg);
        }

        &_open,
        &_active {
          background: $filter-active-btn-background;
          color: $filter-active-btn-text-color;
          border: 1px solid $filter-active-btn-border;

          & > svg {
            transition: all 0.3s ease;
            transform: rotate(90deg);

            * {
              stroke: $filter-active-btn-icon-color;
            }
          }
        }

        &_label {
          align-items: center;
          display: flex;
          gap: 12px;

          .count {
            align-items: center;
            background-color: $filter-section-button-counter-background-color;
            border-radius: $filter-section-button-counter-border-radius;
            color: $filter-section-button-counter-text-color;
            display: flex;
            flex-direction: row;
            font-size: $text-sm;
            font-weight: bold;
            height: 22px;
            justify-content: center;
            line-height: 16px;
            width: 22px;
          }
        }
      }

      &__panel {
        background-color: $background-page;
        border-radius: 8px;
        box-shadow: 0px 6px 12px 0px #00000033;
        max-height: 472px;
        padding: 16px;
        position: absolute;
        width: 320px;
        z-index: 3;

        @media screen and (max-width: 420px) {
          width: 300px;
        }

        &_list {
          display: flex;
          flex-direction: column;
          gap: 11px;
          max-height: 225px;
          padding: 0 24px;
          overflow: auto;

          @include media(sm) {
            max-height: 320px;
          }

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $gray-200;
            border-radius: 8px;
          }
        }

        label {
          height: auto;
          font-size: 1rem;
          line-height: 24px;
        }

        .button_row {
          display: flex;
          background: $white;
          justify-content: space-between;
          padding-top: 16px;
          position: relative;
          width: 100%;

          .panel_button {
            height: 48px;

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 12px;
              gap: 8px;

              span {
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }

  .promotion__search_box {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;

    @include media(md) {
      order: 4;
    }

    @include media(lg) {
      order: 3;
      min-width: 250px;
      max-width: 362px;
    }

    @include media(sm) {
      max-width: 290px;
    }

    @include media(md) {
      max-width: 317px;
    }

    @include media(2xl) {
      max-width: 396px;
    }

    @include media(3xl) {
      max-width: 409px;
    }

    &__placeholder {
      flex-grow: 1;
      border-radius: $blog-header-input-border-radius !important;
      height: 46px;
    }

    &__input {
      flex-grow: 1;
      border-radius: $blog-header-input-border-radius !important;
      border: 1px solid $blog-header-input-border-color !important;
      background-color: $blog-header-input-background !important;
      border-right: 0px;
      padding: 11px 48px 11px 0px;
      font-size: $text-base !important;

      &:hover {
        border: 1px solid $blog-header-input-border-color-activate !important;
      }

      &:focus-within {
        border: 1px solid $blog-header-input-border-color-activate !important;

        .input__label {
          color: $blog-header-input-border-color !important;
        }

        input::placeholder {
          color: $blog-header-input-placeholder-color !important;
        }
      }

      input[type='search'] {
        color: $blog-header-input-text-color !important;
      }

      input {
        .input__control {
          padding: 10px 54px 10px;
        }
      }
    }

    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      -webkit-user-modify: read-write !important;
      border-left: 1px solid $blog-header-input-separator-color !important;
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $blog-header-input-clear-color + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    }

    &__button {
      position: absolute;
      right: 4px;
      border-radius: $blog-header-input-border-radius - 2px;
      padding: 0 !important;
      height: 40px !important;
      width: 40px !important;
      color: $blog-header-button-icon-color !important;
      background: $blog-header-button-search-color !important;
      border: 1px solid $blog-header-button-search-color !important;

      &:not(:disabled):hover {
        color: $blog-header-button-icon-hover-color !important;
        background: $blog-header-button-search-hover-color !important;
        border: 1px solid $blog-header-button-search-hover-color !important;
      }

      &:not(:disabled):focus {
        color: $blog-header-button-icon-hover-color !important;
        background: $blog-header-button-search-hover-color !important;
        border: 1px solid $blog-header-button-search-hover-color !important;
      }
    }

    button {
      svg {
        * {
          stroke: $blog-header-button-icon-color;
        }
      }

      &:hover {
        svg {
          * {
            stroke: $blog-header-button-icon-color;
          }
        }
      }
    }
  }
}
