.instagram {
  @extend .container;

  position: relative;
  margin-bottom: 40px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent, white);
    display: block;
    pointer-events: none;
    width: 16px;

    @include media(sm) {
      right: 16px;
    }
  }

  img {
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.instagram__images {
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  display: grid;
  gap: 10px;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-auto-flow: column;
  height: 270px;
  margin: 0 -16px;
  padding-left: 16px;
  padding-right: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media(sm) {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include media(md) {
    height: 350px;
  }

  @include media(xl) {
    height: 376px;
  }

  .link {
    width: 130px;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }

    @include media(sm) {
      scroll-snap-align: start;
      width: 130px;
    }

    @include media(md) {
      width: 170px;
    }

    @include media(xl) {
      width: 183px;
    }
  }
}
