.wishlist {
  &_title {
    display: flex;
    color: $gray-600;
    font-weight: 700;
    margin-bottom: 40px !important;
  }
  &__container {
    align-self: left;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 480px) {
      padding-left: 0;
      padding-right: 0;

      ol,
      h1 {
        margin-left: 15px;
      }
    }

    &_account {
      width: 100%;
      
      h2 {
        justify-content: center;
      }

      .wishlist__content {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          row-gap: 24px;
        }

        @include media(md) {
          grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
        }
      }

      .wishlist__blank {
        &_body {
          justify-content: center;
        }
      }
    }
  }

  &__content {
    // align-items: stretch;
    display: grid;
    grid-row-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    padding: 1rem 0px 3rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-row-gap: 16px;
    }

    @include media(lg) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @include media(xl) {
      grid-template-columns: repeat(auto-fill, minmax(214px, 1fr));
    }

    .wishlist_product {
      max-width: 251px !important;
      background-color: $background-product-card;
      &:hover {
        z-index: 2;
        animation: 0.35s linear 0s 1 normal;
        box-shadow: 25px 0 20px -20px rgba(0, 0, 0, 0.08),
          -25px 0 20px -20px rgba(0, 0, 0, 0.08);
        transition: box-shadow 0.25s linear 0s;
      }
    }
  }
}

.wishlist__blank_container {
  display: flex;
  align-items: center;
  min-height: 250px;
  margin-bottom: 60px !important;

  @include media(sm){
    min-height: 350px;
  }
}

.wishlist__blank {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 24px;
  border-radius: 30px;
  border: 1px solid $account-message-border-color;
  padding: 24px;
  max-width: 450px;
  max-height: 116px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    padding: 24px 20px;
    margin: 0 auto;
    max-width: 300px;
    max-height: 183px;
  }

  &_body {
    text-align: center;

    @media screen and (min-width: 450px) {
      text-align: left;
    }

    span {
      font-size: $text-xl;
      font-weight: 800;
      line-height: 26px;
    }

    p {
      font-size: $text-sm;
      margin-top: 4px;
      //text-align: center;
      color: $account-message-heading-color !important;
    }
  }

  button {
    height: 36px;
    padding: 6px 30px;
    border-radius: 30px;

    > div {
      font-size: $text-sm;
      line-height: $text-sm;
    }
  }
}
